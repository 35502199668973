/* eslint-disable import/no-named-as-default-member */
import Grid from '@mui/material/Grid'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import React from 'react'

import EmptyState from './EmptyState'
import EventListCard from './EventListCard'
import PaginationSpinner from './Pagination'
import { Event as EventApi } from '../API'
import { DEFAULT_ENTRIES_PER_PAGE } from '../constants/api'
import { ACCESS_TYPES, COMING_SOON } from '../constants/models'
import { ErrorProps } from '../hooks/common'
import { isEventSoldOut } from '../utils/ApiHelpers'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Manila')
dayjs.extend(customParseFormat)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

type Event = Omit<Exclude<EventApi, null>, '__typename'>

interface Props {
  events: Event[]
  apiError: ErrorProps
  apiLoading: boolean
  apiLoadingMore: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onTriggerElement: (additionalTrigger: any) => (node: any) => void
  isCurrent?: boolean
}

const getButtonText = (isCurrent: boolean, item: Event) => {
  if (!isCurrent) return `Details`

  const soldOut = isEventSoldOut(item.categories?.items)

  let buttonText = `View`
  if (item.isComingSoon) buttonText = `Coming Soon`
  else if (item.isOnsiteOnly) buttonText = `See Details`
  else if (!item.categories.items.length) buttonText = `See Details`
  else if (
    ((item.registrationStartDateTime &&
      dayjs().isSameOrAfter(dayjs(item.registrationStartDateTime))) ||
      item.registrationStartDateTime === null) &&
    dayjs().isSameOrBefore(dayjs(item.registrationCutOff))
  ) {
    buttonText = soldOut ? `Sold Out` : `Join Now`

    if ([ACCESS_TYPES.APP].includes(item.accessType)) buttonText = `See Details`
  }
  return buttonText
}

const EventList: React.FC<Props> = ({
  events,
  apiError,
  apiLoading,
  apiLoadingMore,
  onTriggerElement,
  isCurrent = true,
}) => {
  const sortedEvents: Event[] = isCurrent
    ? events.sort((a: Event, b: Event) => {
        if (!!a.priority && !b.priority) return -1
        if (!!b.priority && !a.priority) return 1
        return a.priority - b.priority
      })
    : events.sort((a: Event, b: Event) => {
        return dayjs(b.startDateTime).unix() - dayjs(a.startDateTime).unix()
      })

  return (
    <>
      {apiError && (
        <EmptyState
          header={apiError?.title || ''}
          text={apiError?.message || ''}
        />
      )}
      {!apiLoading && events.length === 0 && (
        <EmptyState
          header="Hmm.. There doesn't appear to have any events"
          text=""
        />
      )}
      {sortedEvents.length > 0 && (
        <>
          <Grid container spacing={4}>
            {sortedEvents.map((item, gridIndex) => {
              const buttonText = getButtonText(isCurrent, item)

              return (
                <Grid item container xs={12} sm={6} md={4} lg={4} key={item.id}>
                  <EventListCard
                    event={{
                      id: item.id,
                      name: item.name,
                      description: item.location || '',
                      other: `${
                        item.isComingSoon === COMING_SOON
                          ? ''
                          : dayjs(item.startDateTime).format('MMM DD, YYYY')
                      }`,
                      slug: item.slug,
                      cover: item.cover,
                      isVirtual: !!item.isVirtual,
                      buttonText,
                      isPast: !isCurrent,
                    }}
                    baseUrl="/events"
                    ref={
                      gridIndex ===
                      events.length -
                        (events.length > DEFAULT_ENTRIES_PER_PAGE
                          ? DEFAULT_ENTRIES_PER_PAGE
                          : 1)
                        ? onTriggerElement(events.length)
                        : null
                    }
                  />
                </Grid>
              )
            })}
          </Grid>
          {apiLoadingMore && <PaginationSpinner />}
        </>
      )}
    </>
  )
}

export default EventList
